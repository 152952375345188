/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation } from "@apollo/client";
import { notification } from "antd";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "../../app/hooks";
import { authenticate } from "../../features/appSlice";
import { setAuthMenuOpen } from "../../features/menuSlice";
import { TOKEN_str } from "../../graphql/client";
import { CUSTOMER_SIGN_IN } from "../../graphql/mutations/mutations";
import {
  CustomerSignInMutation,
  CustomerSignInMutationVariables,
} from "../../graphql/mutations/__generated__/CustomerSignInMutation";
import { classNames } from "../../utils";

type Inputs = {
  password: string;
  email: string;
};

const Login = () => {
  const dispatch = useAppDispatch();
  const [signInCustomer] = useMutation<
    CustomerSignInMutation,
    CustomerSignInMutationVariables
  >(CUSTOMER_SIGN_IN);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    signInCustomer({
      variables: {
        customerSignInInput: data,
      },
    })
      .then((res) => {
        const token = res?.data?.customerSignIn?.token;
        if (token) {
          window.localStorage.setItem(TOKEN_str, token);
        }
        dispatch(authenticate(res.data?.customerSignIn));
        dispatch(setAuthMenuOpen(false));
      })
      .catch((err) => {
        notification.error({ message: "Error", description: err.message });
      });
  };

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-8 space-y-6"
            action="#"
            method="POST"
          >
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  type="email"
                  {...register("email", { required: true })}
                  className={classNames(
                    errors.email ? "border-red-500" : "border-gray-300",
                    "appearance-none rounded-none relative block w-full px-3 py-2 border  placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-sokoGreen-500 focus:border-sokoGreen-500 focus:z-10 sm:text-sm"
                  )}
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  {...register("password", { required: true })}
                  className={classNames(
                    errors.password ? "border-red-500" : "border-gray-300",
                    "appearance-none rounded-none relative block w-full px-3 py-2 border  placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-sokoGreen-500 focus:border-sokoGreen-500 focus:z-10 sm:text-sm"
                  )}
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-sokoGreen-600 focus:ring-sokoGreen-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  {" "}
                  Remember me{" "}
                </label>
              </div>

              <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-sokoGreen-600 hover:text-sokoGreen-500"
                >
                  {" "}
                  Forgot your password?{" "}
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sokoGreen hover:bg-sokoGreen-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sokoGreen-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {/* <!-- Heroicon name: solid/lock-closed --> */}
                  <svg
                    className="h-5 w-5 text-sokoGreen-300 group-hover:text-sokoGreen-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
