import { Tab } from "@headlessui/react";
import { HeartIcon } from "@heroicons/react/outline";
import { classNames } from "../../utils";
import { Link, useLocation } from "react-router-dom";
import { useProduct, useProducts } from "../../hooks/useProducts";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addItem, isInCart, removeItem } from "../../features/cartSlice";
import ResellFeature from "../../components/ResellFeature";
import AlsoBought from "./sections/AlsoBought";
import Cond from "../../components/Cond";
import numeral from "numeral";

export default function Product() {
  const state = useLocation().state as Record<string, any>;
  const { shop } = useAppSelector((state) => state.shop);
  const dispatch = useAppDispatch();
  const { product } = useProduct({
    getProductFilter: {
      id: {
        eq: state.id,
      },
      shop_id: {
        eq: shop?.id,
      },
    },
  });
  const { products } = useProducts({
    getProductsFilter: {
      id: {
        notIn: [state.id],
      },
      random: true,
      shop_id: {
        eq: shop?.id,
      },
    },
    getProductsPagination: {
      limit: 4,
      offset: 0,
    },
  });

  const inCart = useAppSelector((state) =>
    isInCart(state, product?.id as string)
  );

  return (
    <div>
      <div className="max-w-2xl mx-auto lg:max-w-none">
        {/* Product */}
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
          {/* Image gallery */}
          <Tab.Group as="div" className="flex flex-col-reverse">
            {/* Image selector */}
            <div className="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
                {product?.media?.map((image) => (
                  <Tab
                    key={image?.avatar}
                    className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                  >
                    {({ selected }) => (
                      <>
                        {/* <span className="sr-only">{image.name}</span> */}
                        <span className="absolute inset-0 rounded-md overflow-hidden">
                          <img
                            src={image?.avatar ?? ""}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </span>
                        <span
                          className={classNames(
                            selected ? "ring-sokoGreen" : "ring-transparent",
                            "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>

            <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
              {product?.media?.map((image, key) => (
                <Tab.Panel key={key}>
                  <img
                    src={image?.avatar ?? ""}
                    alt={image?.avatar ?? ""}
                    className="w-full max-h-1/2 object-center object-cover sm:rounded-lg"
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
              {product?.title}
            </h1>

            <div className="mt-3">
              <h2 className="sr-only">Product information</h2>
              <p className="text-3xl text-gray-900">
                {product?.currency}
                {numeral(product?.price).format("0,0.00")}
              </p>
            </div>

            {/* Reviews */}
            {/* <div className="mt-3">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        product.rating > rating
                          ? "text-sokoGreen"
                          : "text-gray-300",
                        "h-5 w-5 flex-shrink-0"
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{product.rating} out of 5 stars</p>
              </div>
            </div> */}

            <div className="mt-6">
              <h3 className="sr-only">Description</h3>
              <form className="mb-6">
                {/* Colors */}

                <div className="mt-10 flex sm:flex-col1">
                  <Cond condition={!inCart}>
                    <button
                      type="button"
                      onClick={() =>
                        product &&
                        dispatch(
                          addItem({
                            ...product,
                            quantity: 1,
                          })
                        )
                      }
                      className="max-w-xs flex-1 disabled:bg-gray-600 disabled:hover:cursor-not-allowed bg-sokoGreen border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-sokoGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-sokoGreen sm:w-full"
                    >
                      Add to bag
                    </button>
                  </Cond>
                  <Cond condition={inCart}>
                    <div className="flex space-x-2">
                      <button
                        type="button"
                        // disabled={inCart}
                        onClick={() =>
                          product &&
                          dispatch(removeItem({ ...product, quantity: 1 }))
                        }
                        className="max-w-xs flex-1 bg-gray-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-sokoGreen "
                      >
                        Remove
                      </button>
                      <Link
                        to="/cart"
                        className="max-w-xs flex-1 bg-blue-600 border whitespace-nowrap border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-sokoBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-sokoGreen "
                      >
                        Proceed to checkout
                      </Link>
                    </div>
                  </Cond>

                  <button
                    type="button"
                    className="ml-4 py-3 px-3 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                  >
                    <HeartIcon
                      className="h-6 w-6 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Add to favorites</span>
                  </button>

                  {product?.resell && (
                    <ResellFeature
                      {...{
                        title: product?.title,
                        description: product?.description,
                        avatar: product?.avatar,
                      }}
                    />
                  )}
                </div>
              </form>
              <div
                className="text-base text-gray-700 space-y-6"
                dangerouslySetInnerHTML={{
                  __html: product?.description as string,
                }}
              />
            </div>

            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading" className="sr-only">
                Additional details
              </h2>
            </section>
          </div>
        </div>
        {/* Also Bought */}
        <AlsoBought products={products} />
      </div>
    </div>
  );
}
