import flags from "./assets/flags.json";

export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

export const ModalSize = {
  Small: 40,
  Medium: 60,
  Large: 80,
};

export const getCountryFlag = (country: string): string => {
  const image = flags.find((flag) => flag.code === country)?.image;
  return image ? image : "";
};
