import { useEffect, useState } from "react";
import {
  CheckIcon,
  QuestionMarkCircleIcon,
  XIcon as XIconSolid,
} from "@heroicons/react/solid";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearCart,
  getCartItems,
  removeItem,
  setItemQty,
} from "../../features/cartSlice";
import { setAuthMenuOpen } from "../../features/menuSlice";
import { Link, NavLink, useNavigate } from "react-router-dom";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { classNames } from "../../utils";
import { useProducts } from "../../hooks/useProducts";
import emptyCart from "../../assets/empty_cart.png";
import { MinusIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import { notification } from "antd";
import {
  CreateOrderMutation,
  CreateOrderMutationVariables,
} from "../../graphql/mutations/__generated__/CreateOrderMutation";
import { useMutation } from "@apollo/client";
import { CREATE_CUSTOMER_ORDER } from "../../graphql/mutations/mutations";
import { useDeliveryRates } from "../../hooks/useDeliveryRates";
import Select from "react-select";
import { usePaystackPayment } from "react-paystack";
import { API_KEY, swooveApiKey, swoovebaseUrl } from "../../graphql/client";
import Cond from "../../components/Cond";
import {
  DeliveryOption,
  PaymentOption,
} from "../../graphql/__generated__/globalTypes";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import axios from "axios";
import Loader from "../../components/Loader";
import numeral from "numeral";

type Input = {
  street_address: string;
  city: string;
  country: string;
  postal_code: string;
  suburb: string;
};

const paymentMethods = [
  {
    value: "Cash",
    label: "Cash on Delivery",
    icon: require("../../assets/Cash on delivery - 512.png"),
  },
  {
    value: "Online",
    label: "Online Payment",
    icon: require("../../assets/online payment.png"),
  },
];

const getPaymentMethod = (value: string) => {
  const method = paymentMethods.find((m) => m.value === value);
  return method ? method : null;
};

export default function ShoppingCart() {
  const dispatch = useAppDispatch();
  const { shop } = useAppSelector((state) => state.shop);
  const { user } = useAppSelector((state) => state.app);
  const { products } = useProducts({
    getProductsFilter: {
      shop_id: { eq: shop?.id },
      random: true,
      qty: {
        gte: 1,
      },
    },
    getProductsPagination: {
      limit: 4,
      offset: 0,
    },
  });
  const { rates } = useDeliveryRates({ filter: { shop_id: { eq: shop?.id } } });
  const { register, getValues } = useForm<Input>();
  const navigate = useNavigate();
  const [address_id, setAddress_id] = useState<string>("");
  const [paymentChoice, setPaymentChoice] = useState<PaymentOption | null>(
    null
  );
  const [addAddress, setAddAddress] = useState(false);
  const [delivery, setDelivery] = useState<any>(null);
  const cartItems = useAppSelector(getCartItems);
  const [pickedLocation, setPickedLocation] = useState<any>(null);
  const [estimates, setEstimates] = useState<Record<string, any>[]>([]);
  const [loadingEstimates, setLoadingEstimates] = useState<boolean>(false);
  const [createOrder, { loading }] = useMutation<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >(CREATE_CUSTOMER_ORDER);
  const hasAddress = _.last(user?.address);
  const cartEmpty = cartItems.length === 0;

  const onSubmit = (values: Input, paymentData?: Record<string, any>) => {
    const data: CreateOrderMutationVariables = {
      createOrderInput: {
        customer_id: user?.id ?? "",
        total_amount: cartItems.reduce((acc, item) => {
          return acc + item.price * item.quantity;
        }, 0),
        shop_id: shop?.id ?? "",
        address_id: address_id,
        address: values,
        items: cartItems.map((item) => ({
          product_id: item.id,
          qty: item.quantity,
          price: item.price,
        })),
        payment_type: paymentChoice ?? "",
        reference: paymentData?.reference,
      },
    };
    createOrder({ variables: data })
      .then(() => {
        notification.success({
          description: "Order placed successfully",
          message: "Your order has been placed",
        });
        dispatch(clearCart());
        navigate("/order-history", { replace: true });
      })
      .catch((error) => {
        notification.error({
          message: "Order placement failed",
          description: error.message,
        });
      });
  };

  const handleAffterpayment = (payment_data: Record<string, any>) => {
    const data = getValues();
    console.log("After Payment", payment_data);
    onSubmit(data, payment_data);
  };

  const Subtotal = cartItems?.reduce(
    (prev, curr) => prev + curr.price * curr.quantity,
    0.0
  );
  const delivery_fee = delivery ? delivery?.value : 0;

  useEffect(() => {
    if (pickedLocation) {
      setLoadingEstimates(true);
      setEstimates([]);
      geocodeByAddress(pickedLocation?.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          axios
            .post(
              `${swoovebaseUrl}/estimates/create-estimate?app_key=${swooveApiKey}`,
              {
                pickup: {
                  type: "LATLNG",
                  value: "string",
                  contact: {
                    name: "Tendo Test",
                    mobile: "0506339153",
                    email: "ebaidoo79@gmail.com",
                  },
                  country_code: "GH",
                  lat: "5.6121146",
                  lng: "-0.2536355",
                  location: null,
                },
                dropoff: {
                  type: "LATLNG",
                  value: "string",
                  contact: {
                    name: "Tendo Test",
                    mobile: "0506339153",
                    email: "ebaidoo79@gmail.com",
                  },
                  country_code: "GH",
                  lat: lat,
                  lng: lng,
                  location: null,
                },
                items: [],
              }
            )
            .then((res) => {
              // console.log("Estimate", res.data);
              // setDelivery(res.data.data.estimate);
              setEstimates(res.data.responses.estimates);
            })
            .catch((err) => {
              console.error("Error", err);
            })
            .finally(() => {
              setLoadingEstimates(false);
            });
        });
    }
    return () => {};
  }, [pickedLocation]);

  const initilizePayment = usePaystackPayment({
    subaccount: "ACCT_evxxi5vi1by8epk",
    publicKey: API_KEY,
    email: user?.email ?? "",
    amount: (Subtotal + delivery_fee) * 100,
    currency: "GHS",
    channels: ["card", "bank", "mobile_money", "ussd", "qr"],
  });

  return (
    <div className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="lg:flex justify-between items-center">
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Shopping Cart
        </h1>
        <Cond condition={!user}>
          <button
            onClick={() => dispatch(setAuthMenuOpen(true))}
            className="bg-slate-200 p-2 rounded-md text-sm"
          >
            Sign in / Create a new account to proceed with your order
          </button>
        </Cond>
      </div>

      <div className="mt-12 ">
        <section
          aria-labelledby="cart-heading"
          className={classNames(
            cartEmpty ? "lg:col-span-full" : "lg:col-span-7"
          )}
        >
          <h2 id="cart-heading" className="sr-only">
            Items in your shopping cart
          </h2>

          <ul className="border-t border-b border-gray-200 divide-y divide-gray-200">
            {cartItems.map((product, productIdx) => (
              <li key={product.id} className="flex py-6 sm:py-10">
                <div className="flex-shrink-0">
                  <img
                    src={product.avatar}
                    alt={product.id}
                    className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48"
                  />
                </div>

                <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                  <div className="relative w-full lg:pr-9 grid grid-cols-2 gap-x-6 sm:pr-0">
                    <div>
                      <div className="flex justify-between">
                        <h3 className="text-sm">
                          <Link
                            to=""
                            className="font-medium text-gray-700 hover:text-gray-800"
                          >
                            {product?.title}
                          </Link>
                        </h3>
                      </div>
                      <div className="mt-1 flex text-sm">
                        <p className="text-gray-500">
                          {product?.combination_string?.split("&")?.join(", ")}
                        </p>
                      </div>
                      <p className="mt-1 text-sm font-medium text-gray-900">
                        {product?.currency} {numeral(product.price).format("0,0.00")}
                      </p>
                    </div>

                    <div className="mt-4  sm:mt-0 sm:pr-9">
                      <label
                        htmlFor={`quantity-${productIdx}`}
                        className="sr-only"
                      >
                        Quantity, {product?.title}
                      </label>
                      <div className=" absolute bottom-0 flex items-center space-x-4">
                        <button
                          type="button"
                          disabled={product.quantity === 1}
                          onClick={() =>
                            dispatch(
                              setItemQty({
                                id: product.id,
                                qty: product?.quantity - 1,
                              })
                            )
                          }
                          className="flex disabled:cursor-not-allowed justify-center items-center rounded-full border p-2 border-slate-100"
                        >
                          <MinusIcon
                            className="h-5 w-5 text-gray-600"
                            aria-hidden="true"
                          />
                        </button>
                        <div className="text-lg font-medium text-slate-700">
                          {product?.quantity}
                        </div>
                        <button
                          type="button"
                          disabled={
                            product.limited_stock &&
                            product.qty === product.quantity
                              ? true
                              : false
                          }
                          onClick={() =>
                            dispatch(
                              setItemQty({
                                id: product.id,
                                qty: product?.quantity + 1,
                              })
                            )
                          }
                          className="flex justify-center items-center rounded-full border p-2 border-slate-100"
                        >
                          <PlusIcon
                            className="h-5 w-5 text-gray-600"
                            aria-hidden="true"
                          />
                        </button>
                      </div>

                      <div className="absolute top-0 right-0">
                        <button
                          type="button"
                          onClick={() => dispatch(removeItem(product))}
                          className="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">Remove</span>
                          <XIconSolid className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <p className="mt-4 flex text-sm text-gray-700 space-x-2">
                    {!product.limited_stock ? (
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : product.qty && product.qty < 1 ? (
                      <XIcon
                        className="flex-shrink-0 h-5 w-5 text-red-300"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    )}

                    <span>
                      {!product.limited_stock
                        ? "In stock"
                        : product.qty && product.qty < 1
                        ? `Out of stock`
                        : "In stcok"}
                    </span>
                  </p>
                </div>
              </li>
            ))}
          </ul>

          <Cond condition={cartEmpty}>
            <div className="flex flex-col items-center">
              <img
                src={emptyCart}
                className="h-64 object-contain w-full"
                alt="Empty Cart"
              />
              <span>You have no item(s) in your cart</span>
              <NavLink
                to="/"
                className="p-2 bg-sokoGreen-200 text-white px-11 transform transition-all duration-150 hover:text-white hover:bg-sokoGreen-500 mt-4"
              >
                Browse Products
              </NavLink>
            </div>
          </Cond>
        </section>

        {/* Right Wing */}
        <Cond condition={!cartEmpty && (user as unknown as boolean)}>
          <div className="lg:col-span-5 space-y-4">
            {/* Customer Address Section */}
            <section
              aria-labelledby="summary-heading"
              className="mt-16 bg-gray-50 uppercase rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
            >
              <h2
                id="summary-heading"
                className="text-lg font-medium text-gray-900"
              >
                {hasAddress
                  ? "Choose from your previous addresses or add a new one."
                  : "Address Details"}
              </h2>

              <Cond condition={hasAddress as unknown as boolean}>
                <div className="flex items-center">
                  <span
                    onClick={() => setAddAddress((prev) => !prev)}
                    className="font-bold cursor-pointer my-3"
                  >
                    {addAddress ? "Select from previous" : "Add new address"}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 ml-2 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </div>
              </Cond>

              <Cond
                condition={(hasAddress as unknown as boolean) && !addAddress}
              >
                <div className="">
                  {user?.address
                    ?.filter((el) => el?.street_address)
                    .map((el) => (
                      <div
                        onClick={() => setAddress_id(el?.id ?? "")}
                        className={classNames(
                          el?.id === address_id
                            ? "bg-sokoBlue text-white"
                            : "bg-white ",
                          "rounded p-5 mb-2 flex flex-row justify-between shadow-sm"
                        )}
                      >
                        <div className="flex flex-col">
                          <span>{el?.street_address}</span>
                          <span>{el?.country}</span>
                          <span>{el?.suburb}</span>
                          <span>{el?.postal_code}</span>
                        </div>
                        <div className="flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-11 w-11"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                            />
                          </svg>
                        </div>
                      </div>
                    ))}
                </div>
              </Cond>

              <Cond condition={!hasAddress || addAddress}>
                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  <div>
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Full name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="first-name"
                        name="name"
                        defaultValue={user?.name}
                        autoComplete="given-name"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id="last-name"
                        name="email"
                        defaultValue={user?.email}
                        autoComplete="family-name"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        autoComplete="street_address"
                        {...register("street_address", { required: true })}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      City
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("city", { required: true })}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>
                    <div className="mt-1">
                      <select
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register("country", { required: true })}
                      >
                        <option>Ghana</option>
                        <option>Nigeria</option>
                        <option>Kenya</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="province"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Area
                    </label>
                    <div className="mt-1">
                      <input
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        type="text"
                        {...register("suburb", { required: true })}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="postal-code"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Landmark/Postal code
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("postal_code", { required: false })}
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        defaultValue={user?.phone ?? ""}
                        autoComplete="tel"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </Cond>
            </section>

            {/* Delivery Estimate */}

            <Cond condition={shop?.deliveryOption === DeliveryOption["Manual"]}>
              <section
                aria-labelledby="summary-heading"
                className="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
              >
                <h2
                  id="summary-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  Delivery estimate
                </h2>
                <p>Choose the closest delivery location to you </p>
                <dl className="mt-6 space-y-4">
                  <Select
                    options={rates.map((el) => ({
                      label: `${el?.location} ( GH₵ ${el?.price} )`,
                      value: el?.price,
                    }))}
                    inputValue={""}
                    onChange={(e) => setDelivery(e)}
                    onInputChange={() => {}}
                    value={delivery}
                    isClearable
                    isSearchable
                  />
                </dl>
              </section>
            </Cond>

            <Cond condition={shop?.deliveryOption === DeliveryOption["Swoove"]}>
              <section
                aria-labelledby="swoove-ordering"
                className="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
              >
                <div>
                  <div className=" lg:col-span-5">
                    <h2
                      id="summary-heading"
                      className="text-lg font-medium text-gray-900"
                    >
                      Delivery Estimate
                    </h2>
                    <p>Choose the closest delivery location to you </p>
                  </div>
                </div>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyBvJq8y7K8QFtzAI0teTE0DAuAbEm6q0E8"
                  selectProps={{
                    onChange: (e) => setPickedLocation(e),
                    value: pickedLocation,
                    placeholder: "Search your delivery location",
                  }}
                />
                <Cond condition={loadingEstimates}>
                  <Loader text="Calculating estimates for you" />
                </Cond>
                <Cond condition={estimates.length > 0}>
                  <div className="space-y-4 mt-7">
                    {estimates.map((el) => (
                      <div key={el?.id}>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <div className="flex items-center justify-center h-12 w-12 rounded-md  text-white">
                                <img src={el?.agency_details?.logo} alt="" />
                              </div>
                            </div>
                            <div className="ml-4">
                              <div className="text-sm leading-5 font-medium text-gray-900">
                                {el?.agency_details?.name}
                              </div>
                              <div className="text-sm leading-5 text-gray-500">
                                ({el?.currency_code}){` ${el?.currency_symbol}`}
                                {el?.full_price}
                              </div>
                            </div>
                          </div>
                          <div className="ml-4 flex-shrink-0 flex">
                            <button
                              disabled={delivery?.value === el?.full_price}
                              onClick={() =>
                                setDelivery({
                                  value: el?.full_price,
                                  label: `${el?.location} ( GH₵ ${el?.full_price} )`,
                                })
                              }
                              className="inline-flex disabled:bg-gray-500 disabled:cursor-not-allowed items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-400 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 transition ease-in-out duration-150"
                            >
                              Select
                            </button>
                          </div>
                        </div>
                        <div className="mt-2 text-sm leading-5 text-gray-500">
                          {el?.description}
                        </div>
                      </div>
                    ))}
                  </div>
                </Cond>
              </section>
            </Cond>

            {/* Payment Options */}
            <section className="bg-gray-50 rounded-lg pb-5">
              <div>
                <div className="mt-16  px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5">
                  <h2
                    id="summary-heading"
                    className="text-lg font-medium text-gray-900"
                  >
                    Payment
                  </h2>
                  <p>Choose the payment method</p>
                </div>
              </div>
              {shop?.paymentOption?.map((el) => (
                <div key={el}>
                  <dl className="mt-6 px-11 space-y-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md  text-white">
                            <img
                              src={getPaymentMethod(el as string)?.icon}
                              alt=""
                              className="h-12 w-12 object-contain"
                            />
                          </div>
                        </div>
                        <div className="ml-4">
                          <div className="text-sm leading-5 font-medium text-gray-900">
                            {getPaymentMethod(el as string)?.label}
                          </div>
                          <div className="text-sm leading-5 text-gray-500">
                            {getPaymentMethod(el as string)?.label}
                          </div>
                        </div>
                      </div>
                      <div className="ml-4 flex-shrink-0 flex">
                        <button
                          disabled={paymentChoice === el}
                          onClick={() => setPaymentChoice(el)}
                          className="inline-flex disabled:bg-gray-500 disabled:cursor-not-allowed items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-400 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 transition ease-in-out duration-150"
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </dl>
                </div>
              ))}
            </section>

            {/* Order summary */}
            <section
              aria-labelledby="summary-heading"
              className="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
            >
              <h2
                id="summary-heading"
                className="text-lg font-medium text-gray-900"
              >
                Order summary
              </h2>

              <dl className="mt-6 space-y-4">
                <div className="flex items-center justify-between">
                  <dt className="text-sm text-gray-600">Subtotal</dt>
                  <dd className="text-sm font-medium text-gray-900">
                    {numeral(
                      cartItems?.reduce(
                        (prev, curr) => prev + curr.price * curr.quantity,
                        0.0
                      )
                    ).format("0,0.00")}
                  </dd>
                </div>

                <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                  <dt className="flex items-center text-sm text-gray-600">
                    <span>Delivery estimate</span>
                    <span className="ml-2 flex-shrink-0 text-gray-400 cursor-pointer hover:text-gray-500">
                      <span className="sr-only">
                        Learn more about how shipping is calculated
                      </span>
                      <QuestionMarkCircleIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </span>
                  </dt>
                  <dd className="text-sm font-medium text-gray-900">
                    {delivery?.value
                      ? numeral(delivery?.value).format("0,0.00")
                      : "Undecided"}
                  </dd>
                </div>

                {/* <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                  <dt className="flex text-sm text-gray-600">
                    <span>Tax estimate</span>
                    <a
                      href="#"
                      className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500"
                    >
                      <span className="sr-only">
                        Learn more about how tax is calculated
                      </span>
                      <QuestionMarkCircleIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </a>
                  </dt>
                  <dd className="text-sm font-medium text-gray-900">
                    Undecided
                  </dd>
                </div> */}
                <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                  <dt className="text-base font-medium text-gray-900">
                    Order total
                  </dt>
                  <dd className="text-base font-medium text-gray-900">
                    {numeral(Subtotal + delivery_fee).format("0,0.00")}
                  </dd>
                </div>
              </dl>

              <div className="mt-6">
                <Cond condition={paymentChoice === PaymentOption["Online"]}>
                  <button
                    onClick={() =>
                      initilizePayment(handleAffterpayment, () => {})
                    }
                    disabled={loading}
                    className="w-full disabled:bg-gray-500 disabled:cursor-progress bg-sokoGreen border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-sokoGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-sokoGreen"
                  >
                    Make Payment
                  </button>
                  {/* <PaystackButton
                    subaccount="ACCT_evxxi5vi1by8epk"
                    className="w-full disabled:bg-gray-500 disabled:cursor-progress bg-sokoGreen border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-sokoGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-sokoGreen"
                    publicKey={API_KEY}
                    text="Make Payment"
                    email={user?.email ?? ""}
                    amount={(Subtotal + delivery_fee) * 100}
                    currency="GHS"
                    onSuccess={(data: any) => handleAffterpayment(data)}
                  /> */}
                </Cond>
                <Cond condition={paymentChoice === PaymentOption["Cash"]}>
                  <button
                    onClick={() => handleAffterpayment({})}
                    disabled={loading}
                    className="w-full disabled:bg-gray-500 disabled:cursor-progress bg-sokoGreen border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-sokoGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-sokoGreen"
                  >
                    Place Order
                  </button>
                </Cond>
              </div>
            </section>
          </div>
        </Cond>
      </div>

      {/* Related products */}
      <Cond condition={products.length > 0}>
        <section aria-labelledby="related-heading" className="mt-24">
          <h2
            id="related-heading"
            className="text-lg font-medium text-gray-900"
          >
            You may also like&hellip;
          </h2>

          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {products?.map((relatedProduct) => (
              <NavLink
                to="/product"
                state={{ id: relatedProduct?.id }}
                key={relatedProduct?.id}
                className="group relative"
              >
                <div className="w-full min-h-80 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                  <img
                    src={relatedProduct?.avatar}
                    alt={relatedProduct?.title}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700">
                      <span>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {relatedProduct?.title}
                      </span>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {relatedProduct?.combination_string
                        ?.split("&")
                        .join(", ")}
                    </p>
                  </div>
                  <p className="text-sm font-medium text-gray-900">
                    {numeral(relatedProduct?.price).format("0,0.00")}
                  </p>
                </div>
              </NavLink>
            ))}
          </div>
        </section>
      </Cond>
    </div>
  );
}
