import React, { Fragment, ReactNode } from "react";

type Props = {
  loading?: boolean;
  children?: ReactNode;
};

const Screen = ({ loading, children }: Props) => {
  if (loading) return <h1>Loading</h1>;
  return <Fragment>{children}</Fragment>;
};

export default Screen;
