import { useMutation } from "@apollo/client";
import _ from "lodash";
import React, { useRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAppDispatch } from "../../app/hooks";
import { TOKEN_str } from "../../graphql/client";
import { CREATE_CUSTOMER } from "../../graphql/mutations/mutations";
import {
  CustomerSignUpMutation,
  CustomerSignUpMutationVariables,
} from "../../graphql/mutations/__generated__/CustomerSignUpMutation";

type Inputs = {
  password: string;
  confirmpassword: string;
  email: string;
  phone?: string;
  name: string;
};

const Register = () => {
  const dispatch = useAppDispatch();
  const [createCustomer] = useMutation<
    CustomerSignUpMutation,
    CustomerSignUpMutationVariables
  >(CREATE_CUSTOMER);
  const password = useRef({});
  const { register, handleSubmit, watch } = useForm<Inputs>();
  password.current = watch("password", "");

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    createCustomer({
      variables: {
        customerSignUpInput: {
          ..._.omit(data, ["confirmpassword"]),
        },
      },
    })
      .then((res) => {
        const token = res?.data?.customerSignUp?.token;
        if (token) {
          window.localStorage.setItem(TOKEN_str, token);
        }
        dispatch({
          type: "AUTHENTICATED",
          user: res?.data?.customerSignUp?.user,
        });
      })
      .catch(() => {});
  };
  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Create a new account
            </h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
            <div className="rounded-none shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Fullname
                </label>
                <input
                  id="email-address"
                  {...register("name", { required: true })}
                  type="text"
                  autoComplete="name"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-sokoGreen-500 focus:border-sokoGreen-500 focus:z-10 sm:text-sm"
                  placeholder="Fullname"
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  {...register("email", { required: true })}
                  type="email"
                  autoComplete="email"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-sokoGreen-500 focus:border-sokoGreen-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Phone Number
                </label>
                <input
                  id="phone-number"
                  {...register("phone")}
                  type="tel"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-sokoGreen-500 focus:border-sokoGreen-500 focus:z-10 sm:text-sm"
                  placeholder="Phone number"
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Password
                </label>
                <input
                  id="email-address"
                  {...register("password", { required: true })}
                  type="password"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-sokoGreen-500 focus:border-sokoGreen-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Confirm Password
                </label>
                <input
                  id="password"
                  type="password"
                  {...register("confirmpassword", {
                    required: true,
                    validate: (value) =>
                      value === password.current ||
                      "The passwords do not match",
                  })}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-sokoGreen-500 focus:border-sokoGreen-500 focus:z-10 sm:text-sm"
                  placeholder="Confirm Password"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sokoGreen hover:bg-sokoGreen-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sokoGreen-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {/* <!-- Heroicon name: solid/lock-closed --> */}
                  <svg
                    className="h-5 w-5 text-sokoGreen-300 group-hover:text-sokoGreen-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
