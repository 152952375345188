import { useQuery } from "@apollo/client";
import { getDeliveryRates } from "../graphql/queries/GET_PRODUCT";
import {
  DeliveryRates,
  DeliveryRatesVariables,
} from "../graphql/queries/__generated__/DeliveryRates";

export const useDeliveryRates = (variables: DeliveryRatesVariables) => {
  const { data, loading, error } = useQuery<
    DeliveryRates,
    DeliveryRatesVariables
  >(getDeliveryRates);
  return { rates: data?.deliveryRates ?? [], loading, error };
};
