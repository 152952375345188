import { AuthProps } from "./AuthProvider";

const initialState: AuthProps = {
  isAuthenticated: false,
  user: null,
  error: null,
  isLoading: false,
  isLoggingIn: false,
  dispatch: () => { },
};

const reducer = (state: AuthProps = initialState, action: any): AuthProps => {
  switch (action.type) {
    case "AUTHENTICATED":
      return {
        ...state,
        isAuthenticated: action.user ? true : false,
        user: action.user,
      };
    case "SIGN_OUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      }
    case "ISLOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };

    default:
      return state;
  }
};

export default reducer;
