import {
  ClipboardIcon,
  DownloadIcon,
  ShareIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
import Modal from "./Modal";
import { saveAs } from "file-saver";
import { useCopyToClipboard } from "react-use";

type Props = {
  avatar: string;
  description: string;
  title: string;
};

const ResellFeature = ({ avatar, description, title }: Props) => {
  const [, copyToClipboard] = useCopyToClipboard();
  const [showMenu, setShowMenu] = useState(false);
  const downloadImage = () => {
    saveAs(avatar, `${title}.png`);
  };
  return (
    <>
      <button
        type="button"
        onClick={() => setShowMenu(true)}
        className="ml-4 py-3 px-3 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500"
      >
        <ShareIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
        <span className="sr-only">Resell Product</span>
      </button>

      <Modal show={showMenu} setShow={setShowMenu}>
        <div className="bg-white ">
          <div className="pb-3">
            <span className="text-2xl font-bold">
              Download or copy the description of this product to share{" "}
            </span>
          </div>
          <button
            type="button"
            onClick={() =>
              copyToClipboard(
                description.replace(/<[^>]+>/g, "").replace(/\n/g, "")
              )
            }
            className="bg-green-500 w-full flex px-3 rounded-md items-center justify-between"
          >
            <h3 className="text-white font-medium  p-3">Copy Description</h3>
            <div className="bg-indigo-600 p-2 rounded-full">
              <ClipboardIcon className="h-6 w-6 flex-shrink-0 text-white" />
            </div>
          </button>
          <button
            type="button"
            className="bg-green-700 w-full flex px-3 my-3  rounded-md items-center justify-between"
            onClick={downloadImage}
          >
            <h3 className="text-white font-medium p-3">Download Image</h3>
            <div className="bg-indigo-600 p-2 rounded-full">
              <DownloadIcon className="h-6 w-6 flex-shrink-0 text-white" />
            </div>
          </button>
          {/* {copiedText?.value} */}
        </div>
      </Modal>
    </>
  );
};

export default ResellFeature;
