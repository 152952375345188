import { gql } from "@apollo/client";

export const CREATE_CUSTOMER = gql`
  mutation CustomerSignUpMutation($customerSignUpInput: CustomerInput) {
    customerSignUp(input: $customerSignUpInput) {
      user {
        id
        name
        email
        phone
        address {
          id
          city
          street_address
          country
          postal_code
          suburb
        }
      }
      token
    }
  }
`;

export const CUSTOMER_SIGN_IN = gql`
  mutation CustomerSignInMutation($customerSignInInput: CustomerLoginInput) {
    customerSignIn(input: $customerSignInInput) {
      user {
        id
        name
        email
        phone
        address {
          id
          city
          street_address
          country
          postal_code
          suburb
        }
      }
      token
    }
  }
`;

export const CREATE_CUSTOMER_ORDER = gql`
  mutation CreateOrderMutation($createOrderInput: OrderInput) {
    createOrder(input: $createOrderInput)
  }
`;


export const CANCEL_ORDER = gql`
mutation ProcessOrder($processOrderId: ID!, $processOrderStatus: OrderStatus) {
  processOrder(id: $processOrderId, status: $processOrderStatus)
}
`