import { useQuery } from "@apollo/client";
import { getCategories } from "../graphql/queries/GET_PRODUCT";
import {
  GetProductCategories,
  GetProductCategoriesVariables,
} from "../graphql/queries/__generated__/GetProductCategories";

export const useCategories = (variables?: GetProductCategoriesVariables) => {
  const { data, loading, error } = useQuery<
    GetProductCategories,
    GetProductCategoriesVariables
  >(getCategories, {
    variables,
    fetchPolicy: "cache-and-network",
  });
  return { categories: data?.getProductCategories, loading, error };
};
