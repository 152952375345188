import { useQuery } from "@apollo/client";
import { GET_SHOP } from "../../../graphql/queries/GET_PRODUCT";
import {
  Shop,
  ShopVariables,
} from "../../../graphql/queries/__generated__/Shop";

export const useShop = (variables: ShopVariables) => {
  const { data, loading, error } = useQuery<Shop, ShopVariables>(GET_SHOP, {
    variables,
  });
  return { shop: data?.getShop, loading, error };
};
