import { useRef, Fragment } from "react";
import PropTypes from "prop-types";
import Transition from "./Transitions";
import { useOutsideListener } from "../hooks";
import { useMediaQuery } from "react-responsive";

const Modal = ({ show, setShow, size, children, canClose, height }) => {
  const ref = useRef(null);
  useOutsideListener(ref, () => {
    if (canClose) setShow(false);
  });

  const isTablet = useMediaQuery({ maxWidth: 1224 });
  const isMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <Fragment>
      <Transition show={show}>
        <div className="fixed z-20 bottom-0 inset-x-0 lg:px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
          <Transition
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="opacity-0"
          >
            <div className="fixed  inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-50" />
            </div>
          </Transition>

          <Transition
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-20"
            enterTo="opacity-100 translate-x-0 sm:scale-100"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="opacity-100 translate-x-0 sm:scale-100"
            leaveTo="translate-x-20"
          >
            <div className="fixed z-20 bottom-0 inset-x-0 lg:px-4 lg:pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
              <div
                ref={ref}
                style={{
                  width: `${isMobile ? 100 : isTablet ? 80 : size}vw`,
                  height: `${height}vh`,
                }}
                className="bg-white  rounded-none px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                {children}
              </div>
            </div>
          </Transition>
        </div>
      </Transition >
    </Fragment >
  );
};

Modal.defaultProps = {
  size: 35,
  canClose: true,
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  size: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node,
  canClose: PropTypes.bool,
};

export default Modal;
