import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

type Props = {};

const StoreFront = (props: Props) => {
  return (
    <div>
      <Navbar />
      <main className="max-w-2xl mx-auto px-4 py-3 lg:max-w-7xl lg:px-8">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default StoreFront;
