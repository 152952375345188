import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import appSlice from "../features/appSlice";
import shopSlice from "../features/shopSlice";
import menuSlice from "../features/menuSlice";
import cartSlice from "../features/cartSlice";
import { persistReducer } from "redux-persist";
import { config } from "./persistor";

const rootReducer = combineReducers({
  app: appSlice,
  shop: shopSlice,
  menu: menuSlice,
  cart: cartSlice,
});

const persistedReducer = persistReducer(config, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
