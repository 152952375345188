import React from "react";
import { RotateSpinner } from "react-spinners-kit";

type Props = {
  text?: string;
};

const Loader = ({ text }: Props) => {
  return (
    <div className="flex py-11 flex-col space-y-5 items-center justify-center">
      <RotateSpinner size={40} color="#1A494D" />
      <p>{text}</p>
    </div>
  );
};

export default Loader;
