import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import StoreFront from "./layout/StoreFront";
import HomePage from "./pages/HomePage";
import Orders from "./pages/Orders";
import Product from "./pages/Product";
import ShoppingCart from "./pages/ShoppingCart";

type Props = {};

const App = (props: Props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<StoreFront />}>
          <Route index element={<HomePage />} />
          <Route path="product" element={<Product />} />
          <Route path="product/:id" element={<Product />} />
          <Route path="order-history" element={<Orders />} />
          <Route path="cart" element={<ShoppingCart />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
