import React from "react";
import { RotateSpinner } from "react-spinners-kit";

interface Props {
  text?: string;
}

const PageLoader = ({ text }: Props) => {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <RotateSpinner size={40} color="#1A494D" />
      <p>{text}</p>
    </div>
  );
};

export default PageLoader;
