import storage from "redux-persist/lib/storage";
// import { createTransform } from "redux-persist";
// import { RootState } from "./store";

// export const TransformCredentials = createTransform(
//   (inboundState: RootState, key): any => {
//     return {
//       ...inboundState,
//       user: undefined,
//     };
//   },
//   (outboundState: any, key) => {
//     return {
//       ...outboundState,
//       user: fetchUser(outboundState.token),
//     };
//   },
//   {
//     whitelist: ["authentication"],
//   }
// );

export const config = {
  key: "soko-world-tendo-store",
  storage: storage,
  blacklist: [],
  //   transforms: [TransformCredentials],
};
