import { useQuery } from "@apollo/client";
import { GET_ORDERS } from "../../../graphql/queries/GET_PRODUCT";
import {
  CUSTOMER_ORDERS,
  CUSTOMER_ORDERSVariables,
} from "../../../graphql/queries/__generated__/CUSTOMER_ORDERS";

export const useOrders = (variables: CUSTOMER_ORDERSVariables) => {
  const { data, loading, error } = useQuery<
    CUSTOMER_ORDERS,
    CUSTOMER_ORDERSVariables
  >(GET_ORDERS, { variables });
  return { orders: data?.getOrders, loading, error };
};
