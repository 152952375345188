import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import App from "./App";
import { useAppDispatch } from "./app/hooks";
import PageError from "./components/PageError";
import PageLoader from "./components/PageLoader";
import { addShop } from "./features/shopSlice";
import { useShop } from "./pages/HomePage/hooks/useShop";
import AuthProvider from "./providers/AuthProvider";

const Root = () => {
  const dispatch = useAppDispatch();
  const [subdomain, setSubdomain] = useState("");

  const init = useRef({ dispatch });

  useEffect(() => {
    const host = window.location.host;
    const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
    if (arr.length > 0) {
      setSubdomain(arr[0]);
    }
  }, []);

  const { shop, loading, error } = useShop({
    getShopFilter: { url: subdomain },
  });

  useEffect(() => {
    const { dispatch } = init.current;
    if (shop) {
      dispatch(addShop(shop));
    }
  }, [shop]);

  useEffect(() => {
    document.title =
      _.startCase(_.toLower(subdomain.replace("-", " "))) ||
      "Tenga - where the world shops";
  }, [subdomain]);

  if (loading) return <PageLoader />;
  if (error) return <PageError message={error?.graphQLErrors[0]?.message} />;
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default Root;
