import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8002/graph"
    : "https://api.main.gettenga.app/graph";
export const TOKEN_str = "store-soko-token-web";
export const API_KEY = "pk_test_1a52df498c92587db4609c1eba532e32504338a9";
export const swoovebaseUrl = "https://test.swooveapi.com";
export const swooveApiKey = "f5b59dae-e385-41c9-a799-e0f35567de1a";

const httpLink = createHttpLink({
  uri: URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(TOKEN_str);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "soko-web-token": token ? token : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export { client };
