import React, { useState } from "react";
import Login from "../../pages/Login";
import Register from "../../pages/Register";
import { classNames } from "../../utils";

type Props = {};

type AuthType = "login" | "register";

const AuthModal = (props: Props) => {
  const [tab, setTab] = useState<AuthType>("login");
  return (
    <div>
      <div>
        <div>
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {/* <!-- Current: "border-sokoGreen-500 text-sokoGreen-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" --> */}
              <span
                onClick={() => setTab("login")}
                className={classNames(
                  tab === "login" ? "border-sokoGreen-500" : "",
                  "border-transparent text-gray-500 cursor-pointer hover:text-gray-700 hover:border-gray-300 w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                )}
              >
                Sign in
              </span>

              <span
                onClick={() => setTab("register")}
                className={classNames(
                  tab === "register" ? "border-sokoGreen-500" : "",
                  "border-transparent text-gray-500 cursor-pointer hover:text-gray-700 hover:border-gray-300 w-2/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                )}
              >
                Create an Account
              </span>
            </nav>
          </div>
        </div>
      </div>

      {tab === "login" && <Login />}
      {tab === "register" && <Register />}
    </div>
  );
};

export default AuthModal;
