/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DeliveryAgent {
  SHOP = "SHOP",
  TENDO = "TENDO",
}

export enum DeliveryOption {
  Manual = "Manual",
  Swoove = "Swoove",
}

export enum OrderStatus {
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
  DELIVERED = "DELIVERED",
  INTRANSIT = "INTRANSIT",
  PAID = "PAID",
  PENDING = "PENDING",
  PROCESSED = "PROCESSED",
  PROCESSING = "PROCESSING",
  RETURNED = "RETURNED",
}

export enum PaymentOption {
  Cash = "Cash",
  Online = "Online",
}

export interface AddressInput {
  country?: string | null;
  city?: string | null;
  street_address?: string | null;
  postal_code?: string | null;
  suburb?: string | null;
}

export interface BooleanOperator {
  eq?: boolean | null;
}

export interface CatalogFilter {
  id?: IdOperator | null;
  shop_id?: IdOperator | null;
}

export interface CustomerFilter {
  id?: IdOperator | null;
  shop_id?: IdOperator | null;
}

export interface CustomerInput {
  id?: string | null;
  name?: string | null;
  email: string;
  password: string;
  phone?: string | null;
}

export interface CustomerLoginInput {
  email: string;
  password: string;
}

export interface DateOperator {
  eq?: any | null;
  before?: any | null;
  after?: any | null;
  between?: DateRange | null;
}

export interface DateRange {
  start: any;
  end: any;
}

export interface DeliveryRateFilter {
  id?: IdOperator | null;
  location?: StringOperator | null;
  country?: StringOperator | null;
  post_address?: StringOperator | null;
  landmark?: StringOperator | null;
  lat?: StringOperator | null;
  long?: StringOperator | null;
  price?: MoneyOperator | null;
  shop_id?: IdOperator | null;
}

export interface IdOperator {
  eq?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
}

export interface MoneyOperator {
  eq?: any | null;
  lt?: any | null;
  lte?: any | null;
  gt?: any | null;
  gte?: any | null;
  between?: MoneyRange | null;
}

export interface MoneyRange {
  start: any;
  end: any;
}

export interface NumberOperator {
  eq?: number | null;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  between?: NumberRange | null;
}

export interface NumberRange {
  start: number;
  end: number;
}

export interface OrderFilter {
  id?: IdOperator | null;
  shop_id?: IdOperator | null;
  customer_id?: IdOperator | null;
  status?: StringOperator | null;
  payment_type?: StringOperator | null;
  delivery_type?: StringOperator | null;
  created_at?: DateOperator | null;
  updated_at?: DateOperator | null;
}

export interface OrderInput {
  id?: string | null;
  customer_id: string;
  customer?: CustomerInput | null;
  total_amount?: number | null;
  payment_type?: string | null;
  delivery_type?: string | null;
  shop_id?: string | null;
  items?: (OrderProductInput | null)[] | null;
  address?: AddressInput | null;
  address_id: string;
  status?: OrderStatus | null;
  reference?: string | null;
}

export interface OrderProductInput {
  product_id: string;
  qty?: number | null;
  price?: number | null;
}

export interface Pagination {
  offset?: number | null;
  limit?: number | null;
}

export interface ProductCategoryFilter {
  id?: IdOperator | null;
  shop_id?: IdOperator | null;
  name?: StringOperator | null;
  description?: StringOperator | null;
}

export interface ShopFilter {
  id?: IdOperator | null;
  name?: StringOperator | null;
  description?: StringOperator | null;
  owner_id?: IdOperator | null;
  url?: string | null;
}

export interface StringOperator {
  eq?: string | null;
  notEq?: string | null;
  contains?: string | null;
  notContains?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
  regex?: string | null;
}

export interface productFilter {
  sku?: StringOperator | null;
  title?: StringOperator | null;
  price?: MoneyOperator | null;
  id?: IdOperator | null;
  qty?: NumberOperator | null;
  shop_id?: IdOperator | null;
  verified?: BooleanOperator | null;
  published?: BooleanOperator | null;
  inhouse?: BooleanOperator | null;
  random?: boolean | null;
  catalog?: CatalogFilter | null;
  category?: ProductCategoryFilter | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
