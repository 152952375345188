import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Shop_getShop } from "../graphql/queries/__generated__/Shop";

export interface Shop {
  id?: string;
  avatar?: string;
  name?: string;
  description?: string;
}

interface State {
  shop?: Shop_getShop;
}

const initialState: State = {};

const shopReducer = createSlice({
  name: "shop",
  initialState,
  reducers: {
    addShop: (state, action: PayloadAction<Shop_getShop>) => {
      state.shop = action.payload;
    },
  },
});

export const { addShop } = shopReducer.actions;

export default shopReducer.reducer;
