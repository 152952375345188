import React from "react";

type Props = {
  condition: boolean;
  children: React.ReactNode;
};

const Cond = ({ condition, children }: Props) => {
  if (!condition) return null;
  return <>{children}</>;
};

export default Cond;
