import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MenuState {
  mobileMenuOpen: boolean;
  mobileFiltersOpen: boolean;
  authMenuOpen: boolean;
  //   setMobileMenuOpen: React.Dispatch<SetStateAction<boolean>>;
  //   setMobileFiltersOpen: React.Dispatch<SetStateAction<boolean>>;
}

const initialState: MenuState = {
  mobileFiltersOpen: false,
  mobileMenuOpen: false,
  authMenuOpen: false,
};

const menuReducer = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    setMobileMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.mobileMenuOpen = action.payload;
    },
    setMobileFiltersOpen: (state, action: PayloadAction<boolean>) => {
      state.mobileFiltersOpen = action.payload;
    },
    setAuthMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.authMenuOpen = action.payload;
    },
  },
});

export const { setMobileFiltersOpen, setMobileMenuOpen, setAuthMenuOpen } =
  menuReducer.actions;

export default menuReducer.reducer;
