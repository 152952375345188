import { gql } from "@apollo/client";

export const GET_PRODUCT = gql`
  query GET_PRODUCT($getProductFilter: productFilter) {
    getProduct(filter: $getProductFilter) {
      id
      title
      sku
      limited_stock
      qty
      price
      description
      combination_string
      currency
      avatar
      catalog {
        id
        description
        name
      }
      variations {
        id
        variation_name
        variation_options {
          variation_value
          id
        }
      }
      published
      categories {
        id
        name
        description
      }
      combinations {
        id
        sku
        price
        combination_string
        available_stock
      }
      media {
        id
        avatar
      }
      resell
    }
  }
`;

export const PRODUCTS = gql`
  query Products(
    $getProductsFilter: productFilter
    $getProductsPagination: Pagination
  ) {
    getProducts(
      filter: $getProductsFilter
      pagination: $getProductsPagination
    ) {
      id
      title
      sku
      limited_stock
      qty
      price
      currency
      description
      combination_string
      published
      inhouse
      verified
      cost_price
      weight
      avatar
      categories {
        id
        name
        description
      }
      catalog {
        id
        name
        description
        media
      }
      variations {
        id
        variation_name
        variation_options {
          id
          variation_value
        }
      }
      tags
      media {
        avatar
        id
      }
      resell
      delivery_agent
    }
  }
`;

export const GET_ORDERS = gql`
  query CUSTOMER_ORDERS(
    $getOrdersFilter: OrderFilter
    $getOrdersPagination: Pagination
  ) {
    getOrders(filter: $getOrdersFilter, pagination: $getOrdersPagination) {
      id
      createdAt
      updatedAt
      total_amount
      payment_type
      delivery_type
      shop {
        id
        name
        avatar
      }
      history {
        status
        createdAt
        updatedAt
      }
      customer {
        id
        name
        email
        phone
      }
      order_number
      status
      items {
        qty
        price
        product {
          id
          title
          avatar
          sku
          qty
          price
          currency
          description
        }
        id
        total
        variant_sku
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query Customer($getCustomerFilter: CustomerFilter) {
    getCustomer(filter: $getCustomerFilter) {
      id
      name
      phone
      email
      address {
        country
        city
        street_address
        postal_code
        suburb
        id
      }
    }
  }
`;

export const GET_SHOP = gql`
  query Shop($getShopFilter: ShopFilter) {
    getShop(filter: $getShopFilter) {
      id
      name
      avatar
      cover_image
      description
      deliveryOption
      country
      paymentOption
    }
  }
`;

export const GET_CATALOG = gql`
  query ShopProducts($getCatalogProductsForCustomersFilter: CatalogFilter) {
    getCatalogProductsForCustomers(
      filter: $getCatalogProductsForCustomersFilter
    ) {
      id
      title
      sku
      qty
      price
      currency
      description
      published
      avatar
      categories {
        id
        name
      }
      catalog {
        id
        name
      }
      variations {
        id
        variation_name
        variation_options {
          id
          variation_value
        }
      }
      media {
        avatar
      }
      shop {
        id
        name
        avatar
      }
    }
  }
`;

export const getCategories = gql`
  query GetProductCategories($filter: ProductCategoryFilter) {
    getProductCategories(filter: $filter) {
      id
      name
    }
  }
`;

export const GET_COLLECTIONS = gql`
  query Catalog(
    $getCatalogsFilter: CatalogFilter
    $getCatalogsPagination: Pagination
  ) {
    getCatalogs(
      filter: $getCatalogsFilter
      pagination: $getCatalogsPagination
    ) {
      id
      name
      description
      media
    }
  }
`;

export const getDeliveryRates = gql`
  query DeliveryRates($filter: DeliveryRateFilter, $pagination: Pagination) {
    deliveryRates(filter: $filter, pagination: $pagination) {
      id
      location
      country
      post_address
      landmark
      lat
      long
      price
    }
  }
`;
