import React, { ReactElement } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useReducer } from "react";
import authReducer from "./authReducer";
import { CustomerSignInMutation_customerSignIn_user } from "../graphql/mutations/__generated__/CustomerSignInMutation";
import { useQuery } from "@apollo/client";
import {
  Customer,
  CustomerVariables,
} from "../graphql/queries/__generated__/Customer";
import { GET_CUSTOMER } from "../graphql/queries/GET_PRODUCT";
import PageLoader from "../components/PageLoader";
interface User {
  email?: string;
  phone?: string;
}

export interface AuthProps {
  isAuthenticated: boolean;
  user: CustomerSignInMutation_customerSignIn_user | null;
  error: User | null;
  isLoading: boolean;
  isLoggingIn: boolean;
  dispatch: React.Dispatch<any>;
}

const AuthContext = React.createContext<AuthProps>({
  isAuthenticated: false,
  user: null,
  error: null,
  isLoading: false,
  isLoggingIn: false,
  dispatch: () => {},
});

type AuthProviderProps = {
  children: React.ReactChild;
};

export const useAuth = (): AuthProps => useContext(AuthContext);

function init() {
  const state = window.localStorage.getItem("user_data");
  return JSON.parse(state ?? "{}") || {};
}

export default function AuthProvider({
  children,
}: AuthProviderProps): ReactElement {
  const [state, dispatch] = useReducer(
    authReducer,
    {
      isAuthenticated: false,
      user: null,
      error: null,
      isLoading: false,
      isLoggingIn: false,
      dispatch: () => {},
    },
    init
  );

  const { loading } = useQuery<Customer, CustomerVariables>(GET_CUSTOMER, {
    variables: { getCustomerFilter: { id: { eq: state.user?.id } } },
    onCompleted: (data) => {
      dispatch({ type: "AUTHENTICATED", user: data.getCustomer });
    },
  });

  useEffect(() => {
    window.localStorage.setItem("user_data", JSON.stringify(state));
  }, [state]);

  if (loading) return <PageLoader />;

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}
