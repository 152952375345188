import { createSlice } from "@reduxjs/toolkit";
import { Customer_getCustomer } from "../graphql/queries/__generated__/Customer";

interface User {
  name?: string;
  email?: string;
  phone?: string;
}

export interface Shop {
  id: string;
  avatar: string;
  name: string;
}

interface AppState {
  isAuthenticated: boolean;
  user?: Customer_getCustomer;
  token?: string;
  error: User | null;
  isLoading: boolean;
  isLoggingIn: boolean;
  dispatch: React.Dispatch<any>;
}

const getInitialState = (): AppState => {
  return {
    isAuthenticated: false,
    user: undefined,
    token: undefined,
    error: null,
    isLoading: false,
    isLoggingIn: false,
    dispatch: () => {},
  };
};

const appReducer = createSlice({
  name: "app",
  initialState: getInitialState(),
  reducers: {
    authenticate: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    unauthenticate: (state) => {
      state.user = undefined;
      state.token = undefined;
    },
  },
});

export const { authenticate, unauthenticate } = appReducer.actions;

export default appReducer.reducer;
