import { useQuery } from "@apollo/client";
import {
  PRODUCTS,
  GET_PRODUCT as Product,
} from "../graphql/queries/GET_PRODUCT";
import {
  GET_PRODUCTVariables,
  GET_PRODUCT,
} from "../graphql/queries/__generated__/GET_PRODUCT";
import {
  Products,
  ProductsVariables,
} from "../graphql/queries/__generated__/Products";

export const useProducts = (variables: ProductsVariables) => {
  const { data, loading, error } = useQuery<Products, ProductsVariables>(
    PRODUCTS,
    { variables }
  );
  return { products: data?.getProducts ?? [], loading, error };
};

export const useProduct = (variables: GET_PRODUCTVariables) => {
  const { data, loading, error } = useQuery<GET_PRODUCT, GET_PRODUCTVariables>(
    Product,
    { variables }
  );
  return { product: data?.getProduct, loading, error };
};
