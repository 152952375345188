/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { PlusSmIcon } from "@heroicons/react/solid";
import numeral from "numeral";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Screen from "../../components/Screen";
import { setMobileFiltersOpen } from "../../features/menuSlice";
import { useCategories } from "../../hooks/useCategories";
import { useCollection } from "../../hooks/useCollections";
import { useProducts } from "../../hooks/useProducts";

export default function HomePage() {
  const dispatch = useAppDispatch();
  const { shop } = useAppSelector((state) => state.shop);
  const [categoriesFilter, setCategories] = useState<string[]>([]);
  const { loading, products } = useProducts({
    getProductsFilter: {
      shop_id: { eq: shop?.id },
      category:
        categoriesFilter.length > 0 ? { id: { in: categoriesFilter } } : null,
    },
  });
  const { categories, loading: loadingCategories } = useCategories({
    filter: {
      shop_id: { eq: shop?.id },
    },
  });
  const { collections, loading: loadingCatalog } = useCollection({
    getCatalogsFilter: {
      shop_id: { eq: shop?.id },
    },
  });
  return (
    <Screen loading={loadingCatalog || loadingCategories}>
      <div className="bg-white">
        <div className="border-b border-gray-200 pt-24 pb-10">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900">
            Welcome to {shop?.name}
          </h1>
          <p className="mt-4 text-base text-gray-500">{shop?.description}</p>
        </div>

        <div className="pt-12 pb-24 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
          <aside>
            <h2 className="sr-only">Filters</h2>

            <button
              type="button"
              className="inline-flex items-center lg:hidden"
              onClick={() => dispatch(setMobileFiltersOpen(true))}
            >
              <span className="text-sm font-medium text-gray-700">Filters</span>
              <PlusSmIcon
                className="flex-shrink-0 ml-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>

            <div className="hidden lg:block">
              <form className="divide-y divide-gray-200 space-y-10">
                <div>
                  <fieldset>
                    <legend className="block text-sm font-medium text-gray-900">
                      Category
                    </legend>
                    <div className="pt-6 space-y-3">
                      {categories?.map((option, optionIdx) => (
                        <div key={optionIdx} className="flex items-center">
                          <input
                            id={`category-${optionIdx}`}
                            onChange={() => {
                              option &&
                                setCategories((prev) => {
                                  if (
                                    option &&
                                    prev.includes(option.id as string)
                                  ) {
                                    return prev.filter(
                                      (category) => category !== option.id
                                    );
                                  }
                                  return [...prev, option.id as string];
                                });
                            }}
                            name={`category[]`}
                            defaultValue={option?.id ?? ""}
                            type="checkbox"
                            className="h-4 w-4 border-gray-300 rounded text-sokoGreen focus:ring-sokoGreen"
                          />
                          <label
                            htmlFor={`category-${optionIdx}`}
                            className="ml-3 text-sm text-gray-600"
                          >
                            {option?.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>

                <div className={"pt-10"}>
                  <fieldset>
                    <legend className="block text-sm font-medium text-gray-900">
                      Collection
                    </legend>
                    <div className="pt-6 space-y-3">
                      {collections?.map((option, optionIdx) => (
                        <div key={optionIdx} className="flex items-center">
                          <input
                            id={`category-${optionIdx}`}
                            name={`category[]`}
                            defaultValue={option?.id ?? ""}
                            type="checkbox"
                            className="h-4 w-4 border-gray-300 rounded text-sokoGreen focus:ring-sokoGreen"
                          />
                          <label
                            htmlFor={`category-${optionIdx}`}
                            className="ml-3 text-sm text-gray-600"
                          >
                            {option?.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>
              </form>
            </div>
          </aside>

          <section
            aria-labelledby="product-heading"
            className="mt-6 lg:mt-0 lg:col-span-2 xl:col-span-3"
          >
            <h2 id="product-heading" className="sr-only">
              Products
            </h2>

            {loading ? <div>Loading ...</div> : null}

            <div className="grid grid-cols-2 gap-y-4 sm:grid-cols-2 items-start sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-3">
              {products?.map((product) => (
                <div
                  key={product?.id}
                  className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden"
                >
                  <div className="aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-96">
                    <img
                      src={product?.avatar}
                      alt={product?.avatar}
                      className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                    />
                  </div>
                  <div className="flex-1 p-4 space-y-2 flex flex-col">
                    <h3 className="text-sm font-medium text-gray-900">
                      <NavLink to="product" state={{ id: product?.id }}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {product?.title}
                      </NavLink>
                    </h3>
                    <p
                      className="text-sm text-gray-500"
                      dangerouslySetInnerHTML={{
                        __html: product?.description?.slice(0, 50) || "",
                      }}
                    ></p>
                    <div className="flex-1 flex flex-col justify-end">
                      <p className="text-sm italic text-gray-500">
                        {product?.combination_string?.split("&")?.join(", ")}
                      </p>
                      <div className="flex whitespace-nowrap justify-between">
                        <p className="text-base font-medium text-gray-900">
                          {product?.currency}
                          {numeral(product?.price).format("0,0.00")}
                        </p>
                        <button className="flex whitespace-nowrap">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </Screen>
  );
}
