import React from "react";

interface Props { message?: string }

const PageError = (props: Props) => {
    return (
        <div className="h-screen w-screen flex flex-col items-center justify-center">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-24 w-24 text-red-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>
            <span className="text-3xl font-medium">
                {props.message || "Something went wrong"}
            </span>
        </div>
    );
};

export default PageError;