import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { GET_PRODUCT_getProduct } from "../graphql/queries/__generated__/GET_PRODUCT";

interface CartState {
  products: CartItem[];
}

export interface Shop {
  id: string;
  avatar: string;
  name: string;
}

// Define the initial state using that type
const initialState: CartState = {
  products: [],
};

export interface CartItem extends GET_PRODUCT_getProduct {
  //   variants: string[];
  quantity: number;
}

interface CartQtyType {
  qty: number;
  id: string;
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<CartItem>) => {
      state.products = [...state.products, action.payload];
    },
    removeItem: (state, action: PayloadAction<CartItem>) => {
      state.products = [
        ...state.products.filter((item) => item.id !== action.payload.id),
      ];
    },
    setItemQty: (state, action: PayloadAction<CartQtyType>) => {
      state.products = state.products.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            quantity: action.payload.qty,
          };
        }
        return item;
      });
    },
    clearCart: (state) => {
      state.products = [];
    },
  },
});

export const { addItem, removeItem, clearCart, setItemQty } = cartSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getCartItems = (state: RootState) => state.cart.products;

export const isInCart = (state: RootState, id: string) =>
  state.cart.products.some((item) => item.id === id);

export default cartSlice.reducer;
